import React, { useContext, useState, useRef, createRef, useEffect } from "react";
import SEO from "../components/seo";
import MyImageIndex from "../components/layout/MyImageIndex";
import MySwiper from "../components/layout/MySwiper";
import { useStaticQuery, graphql } from "gatsby";
import {
    GlobalDispatchContext,
    GlobalStateContext,
  } from "../stores/GlobalContextProvider";

const Index = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const globalState = useContext(GlobalStateContext);
    // console.log(globalState);
    // console.log(dispatch);
    // let { globalState, dispatch } = useContext(GlobalContext);
    const data = useStaticQuery(graphql`
        query MyQuery {
            posts: allMarkdownRemark {
                nodes {
                    id
                    frontmatter {
                        date
                        meta
                        title
                        type
                        thumbnail
                        images
                        color
                    }
                    html
                }
                totalCount
            }
        }
    `);
    const postsPerPage = 10;
    let unOrderedPosts = data.posts.nodes;
    useEffect(() => {
        unOrderedPosts.sort((a, b) => {
            const foreSlashAPos = a.frontmatter.date.lastIndexOf("/");
            const yearA = foreSlashAPos > -1 ? a.frontmatter.date.substring(foreSlashAPos) : -1;
            const foreSlashBPos = b.frontmatter.date.lastIndexOf("/");
            const yearB = foreSlashBPos > -1 ? b.frontmatter.date.substring(foreSlashBPos) : -1;
            if (yearA === yearB) {
                const monthA = foreSlashAPos > -1 ? a.frontmatter.date.substring(0, foreSlashAPos) : -1;
                const monthB = foreSlashBPos > -1 ? b.frontmatter.date.substring(0, foreSlashBPos) : -1;
                return monthA < monthB ? 1 : -1;
            }
            return yearA < yearB ? 1 : -1;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let [posts, setPosts] = useState(unOrderedPosts.slice(0, postsPerPage));
    // console.log(unOrderedPosts.length);
    let [pageNo, setPageNo] = useState(1);
    const isClient = typeof window === "object";
    const checkIfAttheBottom = () => {
        const scrolllableMain = document.querySelector("#main");
        if (scrolllableMain.scrollHeight - scrolllableMain.scrollTop <= scrolllableMain.offsetHeight + 200) {
            console.log("Loading more... page = " + (pageNo + 1) + " (*" + postsPerPage + ") = " + (pageNo + 1) * postsPerPage);
            setPageNo(item => item + 1);
        }
    };
    useEffect(() => {
        if (isClient) {
            const scrolllableMain = document.querySelector("#main");
            scrolllableMain.addEventListener("scroll", checkIfAttheBottom);
            return () => {
                scrolllableMain.removeEventListener("scroll", checkIfAttheBottom);
            };
        }
    }, []);
    useEffect(() => {
        setPosts(unOrderedPosts.slice(0, postsPerPage * pageNo));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo]);

    const elementsRef = useRef(unOrderedPosts.map(() => createRef()));
    // const swipersRef = useRef(unOrderedPosts.map(() => createRef()));

    let options = {
        root: isClient ? document.querySelector("#main") : null,
        rootMargin: "0px",
        threshold: 0.5
    };
    const callback = observerObject => {
        // console.log("Observer callback");
        //        console.log("observerObject", observerObject[0].target.id);
        // console.log("new color:", observerObject[0].target.dataset.color);
        dispatch({ type: "SET_COLOR", payload: observerObject[0].target.dataset.color });
        //        console.log("observerObject", observerObject[0].target.style);
    };
    let observer = isClient ? new IntersectionObserver(callback, options) : null;

    useEffect(() => {
        if (isClient) {
            // console.log(elementsRef);
            // console.log(observer);
            //if (observer) {
            posts.map((item, index) => {
                if (elementsRef && elementsRef.current[index]) {
                    return observer.observe(elementsRef.current[index].current);
                } else {
                    return null;
                }
            });
            //}
            return () => {
                if (observer) {
                    posts.map((item, index) => {
                        return observer.disconnect();
                    });
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posts]);

    return (
        <div>
            <SEO title="Studio Milan Nedvěd" />
            {posts &&
                posts.map((item, index) => (
                    <article
                        className="article"
                        key={`article${item.id}`}
                        ref={elementsRef.current[index]}
                        id={`a${item.id}`}
                        data-color={item.frontmatter.color}
                        style={{ borderColor: item.frontmatter.color }}
                    >
                        {globalState.view === "index" ? (
                            <>
                                <h2 className="title">
                                    <span className="no">{unOrderedPosts.length - index > 9 ? unOrderedPosts.length - index : `0${unOrderedPosts.length - index}`}</span>{" "}
                                    <span dangerouslySetInnerHTML={{ __html: item.frontmatter.title }}></span>
                                </h2>
                                <p>{item.frontmatter.type}</p>
                                <div className="media" style={{ marginBottom: "-1.5rem" }}>
                                    <MyImageIndex slides={item.frontmatter.images} workId={item.id} />
                                    {/* {item.frontmatter.images && item.frontmatter.images.map((img, index2) => renderImage(img, index2))} */}
                                    {/*                     <img style={{ height: "100px" }} src={item.frontmatter.thumbnail} alt="" /> */}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex">
                                    <div className="meat">
                                        <h2 className="title">
                                            <span className="no">{unOrderedPosts.length - index > 9 ? unOrderedPosts.length - index : `0${unOrderedPosts.length - index}`}</span>{" "}
                                            <span dangerouslySetInnerHTML={{ __html: item.frontmatter.title }}></span>
                                        </h2>
                                        <div dangerouslySetInnerHTML={{ __html: item.html }} />
                                    </div>
                                    <div className="meta">
                                        <p style={{ marginBottom: "1rem" }}>{item.frontmatter.date}</p>
                                        <div dangerouslySetInnerHTML={{ __html: item.frontmatter.meta }} style={{ marginBottom: "1rem" }} />
                                    </div>
                                </div>
                                <div className="media">
                                    <MySwiper slides={item.frontmatter.images} />
                                </div>
                            </>
                        )}
                    </article>
                ))}
        </div>
    );
};
//export { Consumer as default, Index }
export default Index;
